// src/components/Skills.js

import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skup } from "../data/data";

export default function Collection() {
  return (
    <section id="collection">
      <div className="container px-5 py-20 mx-auto">
        <div className="text-center mb-20">
          <ChipIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Skup
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Prowadzimy skup artykułów szklanych wymienionych ponizej. Artykuły powinny być czyste lub względnie czyste w przypadku zniczy - zastrzegamy sobie prawo do odmowy skupienia artykułu jeśli będzie budził nasze wątpliwości jakościowe.
          </p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {skup.map((skup) => (
            <div key={skup} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                <BadgeCheckIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium text-white">
                  {skup}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}